
import { ChartsProps } from "@/types/charts";
import findIndex from "lodash/findIndex";
import { Line, mixins } from "vue-chartjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "LineChart",
  extends: Line,
  mixins: [mixins.reactiveData],
})
export default class LineChart extends Vue {
  @Prop() data!: any;
  @Prop() customOptions!: any;
  @Prop() chartGradients!: ChartsProps["chartGradients"];

  chartData: any = {
    labels: ["X1", "X2", "X3"],
    datasets: [
      {
        label: "Line Example",
        backgroundColor: "#43a047",
        data: [40, 25, 31],
      },
    ],
  };

  options: { [key: string]: any } = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: true },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  @Watch("data")
  onDataChange() {
    this.updateChartData();
  }
  @Watch("customOptions")
  onOptionsChange() {
    this.updateChartOptions();
  }

  updateChartOptions() {
    if (this.customOptions) {
      this.options = this.customOptions;
      this.renderChart(this.chartData, this.options);
    }
  }
  mounted() {
    this.updateChartData();
    this.renderChart(this.chartData, this.options);
  }
  updateChartData() {
    if (this.data) {
      this.chartData = this.data;
      this.transformChartData();
    }
  }
  transformChartData() {
    // if the gradient configuration is set we will create all needed gradients and set them to our chartData.
    if (this.chartGradients && this.chartData) {
      // @ts-ignore
      const ctx = this.$refs.canvas.getContext("2d");

      // for each gradients to draw
      this.chartGradients.forEach(cg => {
        // Finding the good dataset index
        let datasetIndex = findIndex(this.chartData.datasets, (dataset: any) => {
          return dataset.id === cg.datasetId;
        });
        // if the dataset is found
        if (datasetIndex >= 0) {
          // generating the gradient object and the colorStops
          const gradient = ctx.createLinearGradient(cg.gradient.x0, cg.gradient.y0, cg.gradient.x1, cg.gradient.y1);
          cg.gradient.colorStops.forEach(cs => {
            gradient.addColorStop(cs.offset, cs.color);
          });
          // applying the gradient to the dataset
          this.chartData.datasets[datasetIndex].backgroundColor = gradient;
        }
      });
    }
  }
}
