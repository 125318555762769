
import LoadingMask from "@/components/common/LoadingMask.vue";
import { SpinnerProperties } from "@/types/spinners";
import { VueConstructor } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "AsyncComponent",
  components: { LoadingMask },
})
export default class AsyncComponent extends Vue {
  @Prop() private load!: () => any | Promise<any>;
  @Prop() private component!: VueConstructor;
  //Spinner related
  @Prop() private spinnerComponent!: VueConstructor;
  @Prop({ default: () => ({}) })
  private spinnerConfig!: SpinnerProperties;
  @Prop({ default: true })
  private spinnerFlex!: boolean;
  //Loading related props
  @Prop() private additionalAsyncData!: {};
  @Prop() private staticData!: {};
  // Boolean which will be watched. If true, the component will be load again.
  @Prop({ default: undefined })
  private eventBus!: Vue | undefined;
  // After the reloading of the component, this onReload function will be called.
  @Prop() private onReload!: () => any;
  @Prop({ default: true })
  private autoHeight!: boolean;

  loading: boolean = false;
  defaultSpinnerConfig: SpinnerProperties = {
    size: "s",
    color: "#373737",
  };
  asyncData = {};
  finishLoading = false;

  /**
   * Please note, Generally, The async component will resolve your load method and will set the loaded data
   * Inside your component.
   * Beside that, You have 2 additional hooks that allow to the add data to your component
   *
   * 1) Passing staticData, will be binded BEFORE load to your component.
   * 2) Passing additionalAsyncData will be binded to the data that was fetched from the server and will be
   *    Injected when the server data will arrive.
   *
   *
   *
   * To reload the component dynamically :
   * Provide a 'shouldReload' prop (boolean).
   * When evaluated to true, the component will reload and the 'onReload' prop (function) will be triggered
   *
   * Note that in this case, the 'load' props should be implemented in a method (and not in a computed) which return a Promise to avoid cache problems.
   */

  //The actual injected data, Assembly of the static data and the server resolved data.
  get loadedData(): {} {
    const staticData = Object.create(this.staticData || {});
    return Object.assign(staticData, this.asyncData);
  }

  //Returns the override of the default config by the outter
  get getSpinnerConfig(): SpinnerProperties {
    return { ...this.defaultSpinnerConfig, ...this.spinnerConfig };
  }

  onShouldReload() {
    this.loadComponent().then(
      () => {
        this.onReload && this.onReload();
      },
      err => {
        throw err;
      },
    );
  }

  async created() {
    await this.validateComponent();
    await this.loadComponent();

    if (this.eventBus) {
      this.eventBus.$on("shouldReload", this.onShouldReload);
    }
  }

  beforeDestroy() {
    if (this.eventBus) {
      this.eventBus.$off("shouldReload");
    }
  }
  validateComponent(): void {
    if (!!this.eventBus && !this.$jfUtil.isFunction(this.onReload)) {
      if (process.env.NODE_ENV !== "production") {
        this.$log.warn("AsyncComponent have an active reload watcher but the onReload method is not defined !");
      }
    }
  }

  loadComponent(): Promise<any> {
    return new Promise((resolve, reject) => {
      const loadPromise: Promise<any> = this.$jfUtil.isFunction(this.load) ? this.load() : this.load;

      if (!this.$jfUtil.isPromise(loadPromise)) {
        this.$log.warn("AsyncComponent relies on a promised based load method!");
      }

      this.loading = true;
      this.finishLoading = false;

      loadPromise
        .then(
          (data: any) => {
            this.loading = false;

            //Setting up loaded data, NOTE that we allow the inject to the component additional data that was sent by the parent
            this.asyncData = Object.assign(this.additionalAsyncData || {}, data);

            this.finishLoading = true;
            resolve(undefined);
          },
          (err: Error) => {
            this.loading = false;
            reject(err);
          },
        )
        .catch(error => {
          reject(error);
        });
    });
  }
}
